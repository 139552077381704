<template>
<div :widget-id="widget.id" :widget-type="widget.type">
    <div class="text-center p-1">
        شاخص نمودار : {{ widget.data.label }}
    </div>
    <div class="widget-min-box chart-box">
        <HighCharts :options="chartOptions" v-if="chartOptions"></HighCharts>
    </div>
</div>
</template>

<script>
// این کامپوننت دارای اجزای ویجت نمودار ها در ویجت ساز داشبورد می باشد .
import HighCharts from '@/plugins/HighCharts.vue'

export default {
    name: 'Chart',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        HighCharts
    },
    data: function () {
        return {
            chartOptions: null,
            chartData: null,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                this.chartData = this.data.chartData;
                // تزریق داده های ورود به کامپوننت
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        // این متد تزریق یک سری داده های اولیه و همچنین مدیریت و تنظیم داده های دیگر برای آماده سازی چارت و نمودار را بر عهده دارد
        loadData() {
            let chartType = this.widget.data.chartType;
            let chartFrame = this.widget.data.chartFrame;
            let chartData = JSON.parse(this.chartData);

            let chartOptions;
            if (chartType == 'line') {
                chartOptions = {
                    chartData: chartData,
                    chartType: "line",
                    tooltipTitle: 'قیمت',
                    markerEnabled: false,
                    gridLineStyle: "longdash",
                    fillOpacity: 0.1,
                    markerLineWidth: 1,
                    backgroundColor: false,
                    plotColor: "#54a7da",
                    markerFillColor: "#a9c0ce",
                    plotShadow: false,
                    navigatorEnabled: true
                }
            } else if (chartType == 'area') {
                chartOptions = {
                    chartData: chartData,
                    chartType: "area",
                    chartTitle: "",
                    yAxisTitle: "",
                    gridLineStyle: "longdash",
                    fillOpacity: 0.1,
                    markerLineWidth: 1,
                    yAxisLineColor: "#feeeec",
                    backgroundColor: false,
                    plotColor: "#892731",
                    markerFillColor: "#feeeec",
                    plotShadow: false,
                    tooltipAdDate: true,
                    navigatorEnabled: true
                }
            } else if (chartType == 'candle') {
                chartOptions = {
                    chartData: chartData,
                    chartType: 'candlestick',
                    chartTitle: "",
                    yAxisTitle: "",
                    gridLineStyle: "longdash",
                    fillOpacity: 0.1,
                    markerLineWidth: 1,
                    yAxisLineColor: "#feeeec",
                    backgroundColor: false,
                    plotColor: "#f45b5b",
                    markerFillColor: "#feeeec",
                    tooltipAdDate: true,
                    navigatorEnabled: true,
                }
            }

            if (chartFrame == 'history') {
                chartOptions.inputEnabled = true;
                chartOptions.buttonEnabled = true;
            } else {
                chartOptions.tooltipOnlyTime = true;
                chartOptions.buttonEnabled = false;
            }
            this.chartOptions = chartOptions;
        },
        // این متد وظیفه هندل کردن مودال تنظیمات نمودار در ویجت داشبورد است
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Chart.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        symbol: this.widget.data.symbol,
                        chartType: this.widget.data.chartType,
                        chartFrame: this.widget.data.chartFrame,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);
            // هندل کال بک
            this.$root.$on(callbackEvent, (settings) => {
                this.widget.title = settings.title;
                this.widget.data.symbol = settings.symbol;
                this.widget.data.label = settings.label;
                this.widget.data.chartType = settings.chartType;
                this.widget.data.chartFrame = settings.chartFrame;

                let widget = this.$helpers.unbindObject(this.widget);
                // ذخیره سازی اطلاعات ویرایش شده ویجت
                this.$helpers.saveWidget(widget).then(() => {
                    this.$helpers.getWidgets({ widget_id: this.widget.id }).then((widget) => {
                        this.chartData = widget[0].data.chartData;

                        this.chartOptions = null;
                        setTimeout(() => {
                            this.loadData();
                        }, 100);
                    });
                });
            });
        },
    },
}
</script>
